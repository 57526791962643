@import "../styles/variables.sass"

.project-card-container
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 30px
  margin-top: 30px

.project-card
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  border-radius: 10px
  padding: 10px 0px 20px 0px
  margin: auto
  background-color: $primary-color
  width: 325px

  &:hover
    transform: scale(1.02)

.project-card-thumbnail
  width: 100%

.project-card-information-container
  padding: 0px 20px 0px 20px

.project-card-title
  margin-top: 15px
  color: $accent-color

.project-card-description
  margin-top: 5px

.project-card-technologies
  margin-top: 5px

.project-card-links
  margin-top: 15px

.project-card-github
  cursor: pointer
  color: $accent-color
  text-decoration: none
  margin-right: 5px
  font-size: 20px

.project-card-demo
  cursor: pointer
  color: $accent-color
  text-decoration: none
  margin-left: 5px
  font-size: 20px