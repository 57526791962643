@import "./variables.sass"
  
*
  margin: 0px
  padding: 0px
  box-sizing: border-box

body
  background-color: $primary-color
  font-family: $font-family

a
  text-decoration: none

.page-wrapper
  max-width: 1000px
  margin: 0 auto
  padding: 25px 10px 25px 10px