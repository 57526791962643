@import "../styles/variables.sass"

.home-profile-container
  background-color: $secondary-color
  border-radius: 10px

.home-profile-background-image
  width: 100%
  height: 250px
  object-fit: cover
  background-position: center center
  border-radius: 10px 10px 0px 0px

  @media screen and (max-width: 768px)
    height: 160px

.home-profile-image-container
  position: relative
  bottom: 110px
  left: 50px

  @media screen and (max-width: 425px)
    left: 50%
    margin-left: -100px

.home-profile-image
  position: absolute
  border-radius: 50%
  border: 5px solid $secondary-color
  width: 200px

.home-profile-about-container
  width: 100%
  border-radius: 0px 0px 10px 10px
  padding: 100px 50px 50px 50px
  color: $light-color

  @media screen and (max-width: 768px)
    padding: 100px 25px 25px 25px

  @media screen and (max-width: 425px)
    text-align: center

.home-profile-about-container h1

  @media screen and (max-width: 425px)
    font-size: 24px

.home-profile-about-subtext
  font-size: 20px
  font-style: italic
  color: $accent-color

.home-information-container
  margin-top: 25px
  display: grid
  gap: 25px
  grid-template-columns: 2fr 1fr

  @media screen and (max-width: 768px)
    grid-template-columns: 1fr

.home-content-pod
  background-color: $secondary-color
  border-radius: 10px
  padding: 50px

  @media screen and (max-width: 768px)
    padding: 25px

.home-content-pod-title
  color: $accent-color

.home-content-pod-text
  color: $light-color

.home-resume-item
  display: grid
  grid-template-columns: auto 1fr
  gap: 10px
  margin-top: 10px

.home-resume-item-icon
  width: 50px
  margin-left: -7px
  filter: $icon-light-color

.home-resume-item-description
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start

.home-resume-item-button
  cursor: pointer
  background-color: $accent-color
  border-radius: 6px
  border: none
  font-weight: bold
  padding: 4px