@import "../styles/variables.sass"

.projects-container
  padding: 50px
  background-color: $secondary-color
  color: $light-color
  border-radius: 10px

  @media screen and (max-width: 768px)
    padding: 20px

.projects-header
  font-size: 35px

.projects-body
  font-size: 20px
  color: $accent-color

.projects-line
  margin-top: 20px