@import "../styles/variables.sass"
    
.contact-form
  padding: 20px
  display: flex
  flex-direction: column
  text-align: center
  background-color: $secondary-color
  color: $light-color
  border-radius: 10px

.contact-form-header
  margin-top: 10px
  font-size: 20px
  font-weight: bold
  color: $accent-color

.contact-form-input
  margin-top: 10px
  width: 100%
  padding: 10px
  border-radius: 10px
  border: none

.contact-form-message
  margin-top: 10px
  resize: none
  width: 100%
  height: 125px
  padding: 10px
  border-radius: 10px
  border: none

.contact-form-submit
  width: 100%
  padding: 10px
  border-radius: 10px
  border: none
  font-size: 20px
  font-weight: bold
  margin-top: 5px
  background-color: $accent-color
