@import "../styles/variables.sass"

.blog-container
  padding: 50px
  background-color: $secondary-color
  color: $light-color
  border-radius: 10px

  @media screen and (max-width: 768px)
    padding: 20px

.blog-header
  text-align: center
  color: $accent-color

.blog-line
  margin: 20px

.blog-list
  font-weight: bold
  font-size: 20px

  li
    list-style: none
    margin: 20px

  a
    color: $accent-color
    text-decoration: none