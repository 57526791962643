@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap')

// Fonts
$font-family: 'Poppins', sans-serif

// Colors
$primary-color: #1A1C20
$secondary-color: #0A0B0C
$light-color: #E8E8E9
$accent-color: #6D8A94
$icon-light-color: invert(95%) sepia(5%) saturate(8%) hue-rotate(228deg) brightness(97%) contrast(96%)
$icon-accent-color: invert(56%) sepia(20%) saturate(397%) hue-rotate(149deg) brightness(89%) contrast(89%)