@import "../styles/variables.sass"

.contact-container
  display: grid
  gap: 25px
  grid-template-columns: 65% 35%

  @media screen and (max-width: 768px)
    grid-template-columns: 100%
    
.contact-information-container
  padding: 50px
  background-color: $secondary-color
  color: $light-color
  border-radius: 10px

  @media screen and (max-width: 768px)
    padding: 20px

.contact-information-header
  text-align: center

.contact-information-body
  margin-top: 20px
  font-size: 20px
  text-align: center

.contact-information-line
  margin: 20px

.contact-information-list
  font-weight: bold
  font-size: 20px

  ul
    display: flex
    justify-content: space-around
    align-items: center

  li
    filter: $icon-accent-color
    margin-top: 20px
    list-style: none
    cursor: pointer
    width: 30px

  
